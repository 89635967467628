import { IconConfig } from '@design/buttons/icon/icon-config';

const lockedIconConfig: IconConfig = {
  color: 'system-blue',
  hoverColor: 'system-blue',
  backgroundType: 'circle-system-blue-15',
  iconName: 'lock'
};

const standardIconConfig: IconConfig = {
  color: 'system-green-dark',
  hoverColor: 'system-green-dark',
  backgroundType: 'circle-system-green',
  iconName: 'receipt'
};

export function getIconConfig(options: { isPayrollLocked: boolean; allowGroupHandover: boolean }): IconConfig {
  const { isPayrollLocked, allowGroupHandover } = options;
  return isPayrollLocked && allowGroupHandover ? lockedIconConfig : standardIconConfig;
}
